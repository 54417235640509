import CreateProfileDialog from "@/components/profiles/profile/CreateProfileDialog.vue";
import EditProfileDialog from "@/components/profiles/profile/EditProfileDialog.vue";
import DeleteProfileDialog from "@/components/profiles/profile/DeleteProfileDialog.vue";
import SearchInput from "@/components/global/SearchInput.vue";

export default {
  name: "Profiles",

  data: () => ({
    search: "",
    headers: [
      {
        text: "Aplicación",
        value: "app_name",
        align: "start",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Nombre",
        value: "name",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Descripción",
        value: "description",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Estado",
        value: "active",
        align: "center",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        class: "primary--text",
      },
    ],
    currentPage: 1,
    editedItem: {},
    deletedItem: {},
  }),

  computed: {
    profiles() {
      return this.$store.getters["profiles/getProfiles"];
    },
    paginationPages() {
      return this.$store.getters["profiles/getPaginationPages"];
    },
    isLoading() {
      return this.$store.state.profiles.isLoading;
    },
  },

  watch: {},

  created() {
    this.$store.dispatch("profiles/getProfiles", { page: 1 });
  },

  methods: {
    editItem(item) {
      this.editedItem = { ...item };
    },

    deleteItem(item) {
      this.deletedItem = { ...item };
    },

    listenPagination() {
      this.$store.dispatch("profiles/getProfiles", {
        page: this.currentPage,
        search: this.search,
      });
    },

    resetFilter() {
      this.search = "";
      this.currentPage = 1;
    },
  },
  components: {
    CreateProfileDialog,
    EditProfileDialog,
    DeleteProfileDialog,
    SearchInput,
  },
};
